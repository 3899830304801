import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BUCKET } from '@angular/fire/storage';

import { AppComponent } from './app.component';
import { Router } from '@angular/router';
import { MaudbotRouterModule } from './maudbot-router.module';
import { RouterComponent } from './router/router.component';
import * as Sentry from '@sentry/angular';
import { environment } from '../environments/environment';
import { SharedModule } from './shared/shared.module';
import { CoreModule } from './core/core.module';
import { MarkdownModule } from 'ngx-markdown';

@NgModule({
  declarations: [AppComponent, RouterComponent],
  imports: [
    MaudbotRouterModule,
    BrowserModule,
    CoreModule,
    SharedModule,
    // configure the imports
  ],
  providers: [
    { provide: BUCKET, useValue: `gs://${environment.firebase.storageBucket}` },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [RouterComponent],
})
export class AppModule {}
