import { ProfileRepository } from '../data/profile-repository';
import { Profile } from '../models/profile';
import { UserRepository } from '../data/user-repository';
import { User } from '../models/user';
import { CustomerRepository } from '../data/customer-repository';

export class GetOrCreateProfile {
  constructor(
    private profileRepository: ProfileRepository,
    private userRepository: UserRepository,
    private customerRepository: CustomerRepository,
  ) {}

  async do(token: string): Promise<Profile> {
    const user: User = await this.userRepository.getFromToken(token);
    let profile = await this.profileRepository.getFrom(user.id);
    if (!profile) {
      profile = await this.profileRepository.createFrom(user);
    }
    profile.customer = await this.customerRepository.getCustomer(user.id);
    return profile;
  }
}
