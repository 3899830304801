import { ProfileRepository } from '../data/profile-repository';
import { Profile } from '../models/profile';
import { User, UserRepository } from '..';

export class UpdateProfile {
  constructor(
    private profileRepository: ProfileRepository,
    private userRepository: UserRepository
  ) {}

  async do(token: string, profile: Profile): Promise<Profile> {
    const user: User = await this.userRepository.getFromToken(token);
    return this.profileRepository.update(user, profile);
  }
}
