import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { LoggedInGuard } from 'ngx-auth-firebaseui';
import { SubscriptionGuard } from './core/subscription.guard';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: '/modeloflashpublico' },
  {
    path: 'account',
    loadChildren: () =>
      import('./account/account.module').then((m) => m.AccountModule),
    canActivate: [LoggedInGuard, SubscriptionGuard],
  },
  {
    path: 'pricing',
    loadChildren: () =>
      import('./pricing/pricing.module').then((m) => m.PricingModule),
    canActivate: [LoggedInGuard],
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./login/login.module').then((m) => m.LoginModule),
  },
  { path: ':client', component: AppComponent },
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(routes, { enableTracing: false })],
  exports: [RouterModule],
})
export class MaudbotRouterModule {}
