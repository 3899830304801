export class Customer {
  stripeId?: string;
  status?: 'canceled' | 'active';
  canceledAt?: Date;
  endedAt?: Date;
  created?: Date;
  currentPeriodEnd?: Date;
  currentPeriodStart?: Date;
  stripeLink?: string;
  unitAmout?: number;
  productName?: string;
}
