import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { concatMap, map, share, tap } from 'rxjs/operators';
import { Kind, Message } from './core/model/message';
import { Option } from './core/model/option';
import { environment } from '../environments/environment';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StoryblokRepositoryService {
  private BASE_URL = `https://api.storyblok.com/v1/cdn`;
  private API_TOKEN = environment.storyblok.token; // Preview
  // private API_TOKEN = 'b7XNjQIToJcZ9FNVg6p2XAtt'; // Preview

  private cacheVersion;

  constructor(private http: HttpClient) {
    this.cacheVersion = http.get<any>(`${this.BASE_URL}/spaces/me`, { ...this.getParams() })
      .pipe(
        map(response => response?.space?.version),
        share()
      );
  }

  getParams(){
    return {
      params: {
        token: this.API_TOKEN,
        version: environment.storyblok.version,
      }
    };
  }

  getParamsWithCache(){
    return this.cacheVersion
      .pipe(
        map(version => {
          return {
            params: {
              cv: version,
              ...this.getParams().params
            }
          };
        })
      );
  }

  getScript(client: string) {
    console.log('getScript', client);
    const scriptResponse: Subject<any> = new Subject<any>();
    this.getParamsWithCache().subscribe(params => {
      console.log('Fucking parameters', params);
      this.http
        .get(`${this.BASE_URL}/stories/${client}`, { ...params })
        .pipe(tap((script) => console.log(script)))
        .pipe(
          map((data) => {
            // console.log('MAP', data);
            const {
              story: {
                content: {
                  email,
                  display_name: displayName,
                  photo_top_bar: photoTopBar,
                  conversations: rawScript,
                  whats_message: whatsMessage,
                  whats_phone_property: whatsPhoneProperty
                },
              },
            } = data as any;
            const script = rawScript.map((conversation) => {
              return {
                id: conversation.id,
                property: conversation.property,
                content: conversation.phrase,
                kind: this.kindFromStoryblok(conversation.component),
                options: conversation?.choices?.map((choice) => ({
                  content: choice.phrase,
                  goto: choice.goto,
                  mailTemplate: choice?.mail_template,
                  sendEmail: choice?.special?.indexOf('email') > -1,
                  upload: choice?.special?.indexOf('photo_upload') > -1
                } as Option)),
                images: conversation?.images?.length ? conversation?.images?.map((image) => (image.filename)) : undefined,
              } as Message;
            });
            return {
              whatsMessage,
              whatsPhoneProperty,
              email,
              photoTopBar,
              displayName,
              script
            };
          })
        )
        .subscribe(scriptData => scriptResponse.next(scriptData));
    });
    return scriptResponse;
  }

  private kindFromStoryblok(component: string) {
    switch (component) {
      case 'Talk':
        return Kind.TALK;
      case 'AskText':
        return Kind.ASK_TEXT;
      case 'AskCarousel':
        return Kind.ASK_CAROUSEL;
      case 'Ask':
        return Kind.ASK;
    }
  }
}
