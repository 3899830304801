

<mat-drawer-container class="example-container" autosize hasBackdrop="true">
  <mat-drawer #drawer class="example-sidenav w-4/5 md:w-2/5" mode="side">
<!--    <p>Auto-resizing sidenav</p>-->
<!--    <p *ngIf="true">Lorem, ipsum dolor sit amet consectetur.</p>-->
<!--    <button (click)="true" mat-raised-button>-->
<!--      Toggle extra text-->
<!--    </button>-->

    <mat-list>
      <div mat-subheader>Menu</div>
      <mat-list-item class="hover:bg-background-hover cursor-pointer">
        <mat-icon mat-list-icon>logout</mat-icon>
        <div mat-line>Logout</div>
      </mat-list-item>

<!--      A divider Sample-->
<!--      <mat-divider></mat-divider>-->
<!--      <div mat-subheader>Notes</div>-->
<!--      <mat-list-item>-->
<!--        <mat-icon mat-list-icon>note</mat-icon>-->
<!--        <div mat-line>Teste 2</div>-->
<!--        <div mat-line>&#45;&#45;232</div>-->
<!--      </mat-list-item>-->
    </mat-list>
  </mat-drawer>

  <mat-toolbar class="fixed top-0 bg-surface text-on-surface">
    <button mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon" (click)="drawer.toggle()">
      <mat-icon>menu</mat-icon>
    </button>
    <span>Maudbot</span>
<!--    <button mat-icon-button class="example-icon favorite-icon" aria-label="Example icon-button with heart icon">-->
<!--      <mat-icon>favorite</mat-icon>-->
<!--    </button>-->
<!--    <button mat-icon-button class="example-icon" aria-label="Example icon-button with share icon">-->
<!--      <mat-icon>share</mat-icon>-->
<!--    </button>-->
  </mat-toolbar>

  <div class="flex-col content-center md:w-1/2 md:mx-auto mx-sm">

<!--    <div class="example-sidenav-content">-->
<!--      <button type="button" mat-button (click)="drawer.toggle()">-->
<!--        Toggle sidenav-->
<!--      </button>-->
<!--    </div>-->

    <ng-container *ngIf="(profile | async) as localProfile else loading">

      <!--  TODO: Finish the Account Component-->

      <p class="text-dm mb-md">
        Você assinou o plano
        <span class="whitespace-no-wrap">{{localProfile.customer.productName}},</span>
        no valor de
        <span class="whitespace-no-wrap">{{localProfile.customer.unitAmout / 100 | currency : 'BRL' }}.</span>
        Clique no ícone de ferramenta para recibos, atualizar assinatura e pagamento
      </p>
      <p>Preencha seus dados: (alguns serão utilizados no seu maudbot)</p>
      <app-profile-free
        class="block"
        [profile]="localProfile"
        (save)="onSave($event)"
        [saving]="state.saving"
        [loading]="state.loading"
        [validatingSlug]="state.validatingSlug"
      ></app-profile-free>
    </ng-container>
    <!--Skeleton-->
    <ng-template #loading>
      <div
        name="skeleton"
        class="animate-pulse flex-col content-center"
      >
        <div class="h-12 w-full bg-gray-400 mb-5 rounded"></div>
        <div class="h-12 w-full bg-gray-400 mb-5 rounded"></div>
        <div class="h-16 w-full bg-gray-400 mb-5 rounded"></div>
        <div class="h-16 w-full bg-gray-400 mb-5 rounded"></div>
        <div class="h-16 w-full bg-gray-400 mb-5 rounded"></div>
        <div class="h-16 w-full bg-gray-400 mb-5 rounded"></div>
        <div class="h-16 w-full bg-gray-400 mb-5 rounded"></div>
        <div class="h-16 w-full bg-gray-400 mb-5 rounded"></div>
        <div class="h-16 w-full bg-gray-400 mb-5 rounded"></div>
      </div>
    </ng-template>
  </div>

</mat-drawer-container>


