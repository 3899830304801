<div
  class="animate-pulse flex-col content-center md:w-1/2 space-y-md md:m-auto mx-sm"
  *ngIf="!(planPricing | async)"
>
  <div class="h-10 w-3/4 my-md mx-auto bg-gray-400 rounded"></div>
  <div class="h-24 w-full my-md mx-auto bg-gray-400 rounded"></div>
  <div class="h-24 w-full my-md mx-auto bg-gray-400 rounded"></div>
  <div class="h-24 w-full my-md mx-auto bg-gray-400 rounded"></div>
  <div class="h-12 w-3/4 my-md mx-auto bg-gray-400 rounded"></div>
</div>
<div
  class="flex-col content-center md:w-1/2 space-y-md md:m-auto mx-sm"
  *ngIf="(planPricing | async)"
>

  <h1 class="text-lg text-center my-md">{{ (planPricing | async)?.name }}</h1>
  <app-pricing-option
    class=""
    [planPricing]="planPricing"
    (optionSelected)="onPricingOptionSelected($event)"
  ></app-pricing-option>
  <button
    class="w-full h-16 text-xl bg-primary text-on-primary"
    mat-raised-button
    (click)="subscribePlan()"
    data-cy="buy"
    [disabled]="(subscribing | async)"
  >
    <div class="grid grid-cols-3">
      <div class="flex justify-end items-center">
        <mat-spinner [diameter]="20" *ngIf="subscribing | async"></mat-spinner>
      </div>
      <span>
        Assinar plano {{ (selectedPricingOption | async)?.months }}
      </span>
    </div>
  </button>
  <p class="text-sm text-center">
    Assinatura é cobrada de forma automatica, de acordo com o plano.<br />
    Cancele a qualquer momento
  </p>
</div>
