import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  template: `
    <div
      class="flex justify-center h-auto w-full py-6"
    >
      <!--TODO: Why the banner didn't show-->
      <!--TODO: Move this HTML to another file-->
      <img class="h-12 md:h-20 w-auto" src="logo-banner-168.png"/>
    </div>
  `,
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
