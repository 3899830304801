import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { AccountRoutingModule } from './account-routing.module';

/* TODO: Account Module need to have the Routing Module*/
@NgModule({
  declarations: [AccountRoutingModule.components],
  exports: [...AccountRoutingModule.components],
  imports: [SharedModule, AccountRoutingModule]
})
export class AccountModule {}
