import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Kind, Message } from './core/model/message';
import { Option } from './core/model/option';
import { ActivatedRoute } from '@angular/router';
import { catchError, finalize, map, switchMap, take, tap } from 'rxjs/operators';
import { StoryblokRepositoryService } from './storyblok-repository.service';
import { ViewportScroller } from '@angular/common';
import { EventTrackingService } from './event-tracking.service';
import * as Handlebars from 'handlebars';
import KeenSlider from 'keen-slider';
import { MailService } from './mail.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AngularFireStorage } from '@angular/fire/storage';
import { environment as env } from '../environments/environment';
import { MarkdownService } from 'ngx-markdown';
import * as Sentry from '@sentry/angular';

const sampleMessage: Message = {
  content: 'A simple message for Eder',
  kind: Kind.ASK
};

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewInit {
  title = 'app-angular';
  data = {
    script: [],
    currentMessage: {} as Message,
    options: [] as Array<Option>,
    askText: false,
    storyblokInfo: {
      photoTopBar: undefined
    } as any,
    uploading: false
  };
  private baseScript: Array<Message> = [];
  private delay = 300;
  private scriptIndex = 0;
  ASK: Kind = Kind.ASK;
  ASK_TEXT: Kind = Kind.ASK_TEXT;
  ASK_CAROUSEL: Kind = Kind.ASK_CAROUSEL;
  ANSWER: Kind = Kind.ANSWER;
  production = env.production;

  answers: any = {
    foo: 'bar'
  };
  @ViewChild('sliderRef') sliderRef: ElementRef<HTMLElement>;
  @ViewChild('maudinput') maudInput: ElementRef<HTMLElement>;
  @ViewChild('upload') uploadInput: ElementRef<HTMLElement>;

  slider: any = null;
  private quoteEmail: string;
  private client: string;
  private selectedOption: Option;

  displayName: string;
  maudForm: FormGroup;
  private whatsMessage: any;
  private whatsPhoneProperty: any;

  toJson(o) {
    return JSON.stringify(o, null, 2);
  }

  constructor(
    private route: ActivatedRoute,
    private storyblokRepo: StoryblokRepositoryService,
    private viewport: ViewportScroller,
    private eventTrackingService: EventTrackingService,
    private mailService: MailService,
    private storage: AngularFireStorage,
    private markdownService: MarkdownService
  ) {
  }

  fixFbBrowser() {
    if (
      navigator.userAgent.indexOf('Mac OS') > -1
    ) {
      // IOS does not need the fix of file upload on Instagram Browser
      return;
    }
    if (
      navigator.userAgent.indexOf('Instagram') > -1
      || navigator.userAgent.indexOf('FB_IAB') > -1
    ) {
      window.parent.location.assign('https://account.maudbot.com/link/?link=' + window.location.href);
    }
  }

  ngOnInit(): void {
    this.fixFbBrowser();
    this.maudForm = new FormGroup({
      input: new FormControl('', [
        Validators.required
      ])
    });
    // this.setATest();
    this.route.paramMap
      .pipe(map((params) => params.get('client')))
      .pipe(
        tap((client) => {
          this.client = client;
        })
      )
      .pipe(switchMap((client) => this.storyblokRepo.getScript(client)))
      .subscribe((data) => {
        this.eventTrackingService.setClient(this.client);
        this.eventTrackingService
          .trackEvent('Start Bot')
          .subscribe();
        // TODO: Reduce the number of variables - i do not know why i did not stored the return of storyblok into a single variable
        this.data.storyblokInfo = data;
        this.whatsMessage = data.whatsMessage;
        this.whatsPhoneProperty = data.whatsPhoneProperty;
        this.baseScript = data.script;
        this.quoteEmail = data.email;
        this.displayName = data.displayName;
        this.nextScriptMessage();
      });
  }

  ngAfterViewInit(): void {
  }

  private skipOption: Option = {
    content: 'Pular'
  };

  uploadFile(event) {
    this.data.script.push({
      kind: Kind.ASK,
      content: 'Aguarde um momento por favor...'
    });
    this.data.uploading = true;
    setTimeout(() => this.viewport.scrollToPosition([0, 99999]));
    // this.startConversation();
    const fileName = ((new Date()).toISOString())
      .concat('_tattoo_ref');
    const file = event.target.files[0];
    const filePath = fileName;
    const ref = this.storage.ref(filePath);
    const task = ref.put(file);
    const propertyKey = this.getCurrentProperty() + '_url';
    task.snapshotChanges()
      .pipe(
        tap(() => {
          throw new Error('Upload Error');
        }),
        catchError((err) => {
          this.data.script.push({
            kind: Kind.ASK,
            content: 'Tivemos problemas em receber o arquivo, poderia por gentileza tentar novamente? Caso deseje, pode pular essa etapa.'
          });
          if (this.data.options.indexOf(this.skipOption) === -1) {
            this.data.options.push(this.skipOption);
          }
          setTimeout(() => this.viewport.scrollToPosition([0, 99999]));
          console.log('Error on Upload', err);
          Sentry.captureException(err, { tags: { client: this.client } });
          this.data.uploading = false;
          throw err;
        }),
        finalize(() => {
          ref.getDownloadURL()
            .pipe(take(1), catchError(e => {
              throw e;
            }))
            .subscribe((downloadUrl) => {
              this.data.uploading = false;
              this.setResponseObject(propertyKey, downloadUrl);
              this.setAnswer('Arquivo enviado');
              this.startConversation({ skipTyping: true });
            });
        })
      ).subscribe();
  }

  setupKeenSlider() {
    this.sliderRef.nativeElement.setAttribute('hidden', 'true');
    this.slider = new KeenSlider(this.sliderRef.nativeElement, {
      slidesPerView: 1.5,
      mode: 'free-snap',
      spacing: 15,
      centered: true,
      loop: true
    });
  }

  nextScriptMessage() {
    if (this.scriptIndex >= this.baseScript.length) {
      console.log('Maudbot vai descançar agora (fim das mensagens)');
      return;
    }
    this.data.currentMessage = this.baseScript[this.scriptIndex];
    if (this.data.currentMessage?.images?.length) {
      setTimeout(() => this.setupKeenSlider(), 10);
    }
    this.scriptIndex++;

    this.startConversation();
  }

  startConversation(options?: { skipTyping: boolean }) {

    this.data.currentMessage.content = Handlebars.compile(
      this.data.currentMessage.content
    )(this.answers);

    this.data.currentMessage.content = this.markdownService.compile(this.data.currentMessage.content);
    this.data.script.push(this.data.currentMessage);
    this.data.options = undefined;
    this.data.askText = false;

    /*Typing effect*/
    setTimeout(() => {
      this.viewport.scrollToPosition([0, 99999]);
      if (!env.production) {
        this.setOptionsOrNextMessage();
      }
    });
  }

  public setOptionsOrNextMessage() {
    /*After Typing*/
    this.data.options = this.data.currentMessage.options;
    if ([Kind.ASK_TEXT].indexOf(this.data.currentMessage.kind) >= 0) {
      this.data.askText = true;
      // setTimeout(() => this.maudInput.nativeElement.focus());
    }
    if ([Kind.TALK, Kind.ANSWER].indexOf(this.data.currentMessage.kind) >= 0) {
      setTimeout(() => {
        this.nextScriptMessage();
      }, this.delay);
    }
    setTimeout(() => this.viewport.scrollToPosition([0, 99999]));
  }

  selectOption(option: Option) {
    this.selectedOption = option;
    this.eventTrackingService
      .trackEvent('Select option', {
        message: this.data.currentMessage,
        ...option
      })
      .subscribe();

    if (option.upload) {
      this.uploadInput.nativeElement.click();
      return;
    }

    if (option.sendEmail) {
      this.mailService.sendQuote(this.quoteEmail, this.answers, option.mailTemplate)
        .then(() => {
          console.log('E-mAil sent', arguments);
        })
        .catch((e) => {
          alert(e);
        });
    }
    if (option?.goto !== '') {
      const optionIndex = this.baseScript.findIndex((message, index) => {
        return message.id === option?.goto;
      });
      if (optionIndex >= 0) {
        this.scriptIndex = optionIndex;
      }
    }
    this.setAnswer(option.content);
    this.startConversation({ skipTyping: true });
  }

  get input() {
    return this.maudForm.get('input');
  }

  inputText(option?: Option, el?: KeyboardEvent) {
    this.input.markAsTouched();
    if (!this.input.valid) {
      return;
    }
    this.input.markAsUntouched();
    this.input.markAsPristine();
    const answer = this.maudForm.get('input').value;
    this.maudForm.get('input').setValue('');

    this.eventTrackingService
      .trackEvent('Answer Text', {
        message: this.data.currentMessage,
        answer
      })
      .subscribe();
    this.setAnswer(answer);
    this.startConversation({ skipTyping: true });
  }

  getCurrentProperty() {
    return this.data.currentMessage?.property || this.data.currentMessage.id;
  }

  setAnswer(response: string) {
    this.setResponseObject(this.getCurrentProperty(), response);
    this.data.currentMessage = {
      kind: Kind.ANSWER,
      content: response
    };
  }

  setResponseObject(key, value) {
    this.answers[key] = value;

    if (this.whatsMessage && this.answers[this.whatsPhoneProperty]) {
      this.answers.whatsapp_url = this.formatWhatsMessage(this.answers[this.whatsPhoneProperty], Handlebars.compile(
        this.whatsMessage
      )(this.answers));
    }
  }


  selectImage(image: string) {
    this.setAnswer(image);
    this.nextScriptMessage();
  }

  setATest() {
    this.data.script = new Array<Message>();
    this.data.script.push({
      content: 'Olá, sou a Maud. Irei te ajudar com a sua próxima tatuagem.',
      kind: Kind.ASK,
      options: [{ content: 'Vamos lá.' }]
    });
    this.data.script.push({
      content: 'Você quer o que?',
      kind: Kind.ASK,
      options: [
        { content: 'Dinheiro' },
        { content: 'Mulher' },
        { content: 'Chocolate' }
      ]
    });
  }

  formatWhatsMessage(phone, message) {
    return `wa.me/${formatPhone(phone)}?text=${encodeURI(message)}`;

    function formatPhone(phoneVar) {
      let temp = phoneVar.replace(/[^0-9]+/gi, '');
      console.log('INDEXOF', phone.indexOf('55'));
      if (temp.indexOf('55') !== 0) {
        temp = '55' + temp;
      }
      return temp;
    }
  }
}
