import { Option } from './option';

export enum Kind {
  ASK = 'ASK',
  ASK_TEXT = 'ASK_TEXT',
  ASK_CAROUSEL = 'ASK_CAROUSEL',
  TALK = 'TALK',
  ANSWER = 'ANSWER',
}

export interface Message {
  id?: string;
  property?: string;
  content: string;
  kind: Kind;
  options?: Array<Option>;
  images?: Array<string>;
}

