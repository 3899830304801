import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { PlanPricing, PlanPricingOption } from '../../core/model/plan-pricing';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-pricing-option',
  template: `
    <div class="flex-column space-y-md" *ngIf="planPricing !== null" >
      <mat-card
        *ngFor="let option of (planPricing | async)?.options; index as i"
        [ngClass]="selectedPlan === i ? 'border-primary border-4' : ''"
        [attr.data-cy]="'plan-'+ (i + 1)"
        (click)="selectPlan(i, option)"
      >
        <div class="flex justify-end w-full" *ngIf="option.discountedPrice">
          <span class="line-through text-on-background-lighter">
            {{ option.discountedPrice | currency:'BRL' }}
          </span>
        </div>
        <div class="grid grid-cols-2 w-full">
          <span class="text-lg">
            {{ option.months }}<br />{{ option.price | currency:'BRL' }}
          </span>
          <span
            class="place-self-end text-right text-lg text-on-background-lighter"
            *ngIf="option.discountedPrice"
          >
            {{ option.pricePerMonth | currency:'BRL'}}<br />por mês
          </span>
        </div>
      </mat-card>
    </div>
  `,
  styles: [],
})
export class PricingOptionComponent implements OnInit {
  @Input()
  planPricing: Observable<PlanPricing>;
  @Input()
  selectedPlan: number;
  @Output() optionSelected: EventEmitter<PlanPricingOption> = new EventEmitter<PlanPricingOption>();

  constructor() {}

  ngOnInit(): void {
    this.planPricing.subscribe(planPricing => {
      this.selectPlan(0, planPricing.options[0]);
    });
  }

  selectPlan(i: number, option: PlanPricingOption) {
    this.selectedPlan = i;
    this.optionSelected.emit(option);
  }
}
