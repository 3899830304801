import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { CoreService } from './core.service';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionGuard implements CanActivate {
  constructor(private coreService: CoreService, private router: Router) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return (
      this.coreService
        .loadsubscription()
        // todo: Remover o any daqui.
        .pipe(
          tap(async (subscriptions) => {
            if (subscriptions.length === 0) {
              await this.router.navigate(['pricing']);
            }
          }),
          map((subscriptions) => {
            return subscriptions.length > 0;
          })
        )
    );
  }
}
