import { Component, OnInit } from '@angular/core';
import { PlanPricing, PlanPricingOption } from '../core/model/plan-pricing';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { CoreService } from '../core/core.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.css'],
})
export class PricingComponent implements OnInit {
  planPricing: Observable<PlanPricing>;
  aSelectedPricingOption: Subject<PlanPricingOption> = new BehaviorSubject<PlanPricingOption>(undefined);
  subscribing: Subject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private coreService: CoreService) {
  }

  get selectedPricingOption(): Observable<PlanPricingOption> {
    return this.aSelectedPricingOption.asObservable();
  }

  ngOnInit(): void {
    this.planPricing = this.coreService.loadProducts();
  }

  onPricingOptionSelected(planPricingOption: PlanPricingOption) {
    console.log('onPricingOptionSelected', planPricingOption);
    this.aSelectedPricingOption.next(planPricingOption);
  }

  subscribePlan() {
    this.subscribing.next(true);
    this.selectedPricingOption
      .pipe(first())
      .subscribe(option => this.coreService.subscribePlan(option))
    ;
  }
}
