// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  name: 'development',
  production: false,
  defaults: {
    product: 'prod_IARWHN5pjUGNO7'
  },
  amplitude: {
    apiKey: '25ef73645e2bae5011f50cf28aacddc5'
  },
  firebase: {
    apiKey: 'AIzaSyB3vQZxEFYwMkraslT_v2zW3VG3x9YPcBg',
    authDomain: 'maudbot-dev.firebaseapp.com',
    databaseURL: 'https://maudbot-dev.firebaseio.com',
    projectId: 'maudbot-dev',
    storageBucket: 'maudbot-dev.appspot.com',
    messagingSenderId: '182276772806',
    appId: '1:182276772806:web:7043036292d682ee15d8c7',
    measurementId: 'G-G6CY19FYZ1'
  },
  sentry: {
    dsn: 'https://168462010ea74508a2de9254bf0e4d4b@o450310.ingest.sentry.io/5435017'
  },
  storyblok: {
    token: 'BMN8aJV7lK8xFKEliK0Hjwtt',
    version: 'draft',
  },
  stripe: {
    key: 'pk_test_51Ha6TwBV0lRcwe0joYVbj7Es4XHwmbFwtSfdt9JBVdy0vaRewWrvi6YsvttjeDeT2ytLWU17Ku0Py6hVCvJKF7v500Uho5qDNm'
  },
  api: {
    url: 'https://us-central1-maudbot-dev.cloudfunctions.net/api'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
