import { Injectable } from '@angular/core';
import { Product } from './model/product';
import { BehaviorSubject, from, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { AngularFirestore, DocumentData } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import {
  catchError,
  concatMap,
  concatMapTo, delay,
  expand,
  flatMap,
  map,
  mapTo,
  mergeAll,
  mergeMap,
  mergeMapTo, share,
  switchMap,
  tap
} from 'rxjs/operators';
import { PlanPricing, PlanPricingOption } from './model/plan-pricing';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { loadStripe } from '@stripe/stripe-js/pure';
import { Stripe } from '@stripe/stripe-js';
import firebase from 'firebase';
import User = firebase.User;
import { HttpClient } from '@angular/common/http';
import { Profile } from '@app-angular/maudlib';

@Injectable({
  providedIn: 'root',
})
export class CoreService {
  private stripe: Stripe;
  private user: User;
  savingProfile: Subject<boolean> = new ReplaySubject<boolean>();
  loadingProfile: Subject<boolean> = new ReplaySubject<boolean>();
  validatingSlug: Subject<boolean> = new ReplaySubject<boolean>();
  constructor(
    private auth: AngularFireAuth,
    private firestore: AngularFirestore,
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient
  ) {}

  async getCurrentUser(): Promise<User> {
    if (this.user === undefined) {
      this.user = await this.auth.currentUser;
    }
    return this.user;
  }

  getCurrentUserObservable(): Observable<User> {
    return from(this.getCurrentUser());
  }

  loadProducts(): Observable<PlanPricing> {
    const product =
      this.route.snapshot.queryParams?.product || environment.defaults.product;
    const productSnapshot = this.firestore
      .collection('products')
      .doc(product)
      .get();
    const pricesQuery = this.firestore
      .collection('products')
      .doc(product)
      .collection('prices')
      .get();

    return productSnapshot.pipe(
      // Merge product with sub-collection prices
      mergeMap((snapshot) => {
        // Query Prices
        return pricesQuery.pipe(
          map((prices) => {
            return {
              id: snapshot.id,
              name: '',
              ...snapshot.data(),
              prices: prices.docs.map((doc) => ({ ...doc.data(), id: doc.id })),
            };
          })
        );
      }),
      map(firestoreProductToPlanPricing)
      /*map(() => {
          return {
            name: 'Maud Flashsss',
            options: [
              {
                months: '12 meses',
                price: 'R$ 299',
                pricePerMonth: 'R$ 24,91',
                discountedPrice: 'R$ 29,90',
              },
              {
                months: '6 meses',
                price: 'R$ 149,50',
                pricePerMonth: 'R$ 24,91',
                discountedPrice: 'R$ 29,90',
              },
              {
                months: 'mensal',
                price: 'R$ 29,90',
                pricePerMonth: 'R$ 29,90',
              },
            ],
          } as PlanPricing;
        })*/
    );

    /*
    * Firestore Product
    * {
        "name": "Maudbot Flash",
        "role": null,
        "active": true,
        "description": "Maudbot Flash",
        "images": [
          "https://files.stripe.com/links/fl_test_wWFJRJebW5wHIdyMmQhcNUtJ"
        ],
        "prices": [
          {
            "currency": "brl",
            "interval_count": 1,
            "unit_amount": 1990,
            "type": "recurring",
            "active": true,
            "interval": "month"
          },
          {
            "interval": "month",
            "currency": "brl",
            "type": "recurring",
            "trial_period_days": null,
            "active": true,
            "unit_amount": 9950,
            "interval_count": 6,
            "description": "ganhe 1 mensalidade"
          },
          {
            "type": "recurring",
            "description": "ganhe 2 mensalidades",
            "interval_count": 1,
            "trial_period_days": null,
            "currency": "brl",
            "active": true,
            "interval": "year",
            "unit_amount": 19900
          }
        ]
      }
    * */
    function firestoreProductToPlanPricing(firestoreProduct): PlanPricing {
      const { name, prices } = firestoreProduct;
      return {
        options: prices
          .map((priceInfo) => {
            // TODO: É preciso arrumar os dados na interface
            const price = priceInfo.unit_amount / 100;
            const { id } = priceInfo;

            return {
              id,
              months: getMonthsName(
                priceInfo.interval,
                priceInfo.interval_count
              ),
              price,
              discountedPrice: '2000',
              pricePerMonth:
                price / getMonths(priceInfo.interval, priceInfo.interval_count),
            };

            function getMonthsName(interval: string, count: number) {
              switch (interval + count) {
                case 'month1':
                  return 'Mensal';
                case 'month6':
                  return 'Semestral';
                case 'year1':
                  return 'Anual';
              }
            }

            function getMonths(interval: string, count: number) {
              switch (interval + count) {
                case 'month1':
                  return 1;
                case 'month6':
                  return 6;
                case 'year1':
                  return 12;
              }
            }
          })
          .sort((a, b) => {
            return a.price < b.price ? 1 : -1;
          }),
        name,
      };
    }
  }

  loadsubscription(): Observable<DocumentData[]> {
    return this.auth.user.pipe(
      mergeMap((user) => {
        this.user = user;
        return this.firestore
          .collection('customers')
          .doc(user.uid)
          .collection('subscriptions', (ref) => {
            return ref.where('status', 'in', ['trialing', 'active']);
          })
          .valueChanges();
      })
    );

    // db.collection('customers')
    //   .doc(currentUser)
    //   .collection('subscriptions')
    //   .where('status', 'in', ['trialing', 'active'])
    //   .onSnapshot(async (snapshot) => {
    //     if (snapshot.empty) {
    //       // Show products
    //       document.querySelector('#subscribe').style.display = 'block';
    //       return;
    //     }
    //     document.querySelector('#subscribe').style.display = 'none';
    //     document.querySelector('#my-subscription').style.display = 'block';
    //     // In this implementation we only expect one Subscription to exist
    //     const subscription = snapshot.docs[0].data();
    //     const priceData = (await subscription.price.get()).data();
    //     document.querySelector(
    //       '#my-subscription p'
    //     ).textContent = `Você está pagando ${new Intl.NumberFormat('pt-BR', {
    //       style: 'currency',
    //       currency: priceData.currency,
    //     }).format((priceData.unit_amount / 100).toFixed(2))} por ${
    //       translateInterval(priceData.interval, priceData.interval_count)
    //     } 🥳`;
    //   })
  }

  async subscribePlan(option: PlanPricingOption) {
    const user: User = await this.getCurrentUser();
    if (!user) {
      throw new Error('Usuário não está logado; problema a rota');
    }
    return this.firestore
      .collection('customers')
      .doc(user.uid)
      .collection('checkout_sessions')
      .add({
        price: option.id,
        allow_promotion_codes: true,
        // tax_rates: taxRates,
        success_url: window.location.origin,
        cancel_url: window.location.origin,
        metadata: {
          tax_rate: '10% sales tax exclusive',
        },
      })
      .then((docRef) => {
        docRef.onSnapshot(async (snap) => {
          const { error, sessionId } = snap.data();
          if (error) {
            // TODO: Better error Handling to user and Sentry Integration
            // Show an error to your customer and then inspect your function logs.
            document
              .querySelectorAll('button')
              .forEach((b) => (b.disabled = false));
          }
          if (sessionId) {
            // We have a session, let's redirect to Checkout
            // Init Stripe
            await this.getStripe().then((stripe) => {
              stripe.redirectToCheckout({ sessionId });
            });
          }
        });
      });
  }

  private async getStripe(): Promise<Stripe> {
    if (!this.stripe) {
      this.stripe = await loadStripe(environment.stripe.key);
    }
    return this.stripe;
  }

  loadProfile(): Observable<Profile> {
    this.loadingProfile.next(true);
    return this.getCurrentUserObservable().pipe(
      mergeMap((user) => user.getIdToken()),
      mergeMap((idToken) => {
        console.log('TOKEN', idToken);
        const baseUrl = environment.api.url;
        return this.http
          .get<Profile>(baseUrl + '/myprofile', {
            headers: {
              Authorization: idToken,
            },
          })
          .pipe();
      }),
      tap(() => this.loadingProfile.next(false))
      , share()
    );
  }

  async logged(user: firebase.User) {
    await this.router.navigateByUrl('/account');
  }

  async saveProfile(profile: Profile) {
    this.savingProfile.next(true);
    await this.getCurrentUserObservable()
      .pipe(
        mergeMap((user) => user.getIdToken()),
        mergeMap((idToken) => {
          const baseUrl = environment.api.url;
          console.log('TOKEN', idToken);
          return this.http
            .put<Profile>(baseUrl + '/myprofile', profile, {
              headers: {
                Authorization: idToken,
              },
            })
            .pipe();
        }),
        tap(() => {
          this.savingProfile.next(false);
        })
      )
      .toPromise();
  }

  isValidSlug(slug: string): Observable<boolean> {

    this.validatingSlug.next(true);
    return this.getCurrentUserObservable().pipe(
      delay(500),
      mergeMap((user) => user.getIdToken()),
      mergeMap((idToken) => {
        console.log('TOKEN', idToken);
        const baseUrl = environment.api.url;
        return this.http
          .get<boolean>(baseUrl + `/slug/validate/${slug}`, {
            headers: {
              Authorization: idToken,
            },
          })
          .pipe();
      }),
      tap(() => this.validatingSlug.next(false)),
      // catchError((err, o) => o)
    );
  }
}
