<header class="w-full bg-secondary fixed top-0 shadow-sm">
  <div
    class="flex items-center content-center sm:max-w-screen-md m-auto w-full px-md py-sm"
  >
    <img class="bg-surface rounded-full w-10 h-10" [src]="data?.storyblokInfo?.photoTopBar || '../assets/maudbot-logo.png'" alt=""/>
    <div class="flex flex-col h-auto ml-sm">
      <span class="font-medium">
        {{displayName}}
      </span>
      <span class="text-sm font-medium">
        online
      </span>
    </div>
  </div>
</header>
<div
  class="flex flex-col content-end px-md sm:max-w-screen-md mx-auto pt-xxl min-h-full"
>
  <div class="space-y-md">
    <div
      class="p-md shadow-xl"
      *ngFor="let part of data.script; index as itemIndex"
      [ngClass]="
        part.kind !== ANSWER
          ? 'rounded-r-md rounded-bl-md mr-lg bg-surface'
          : 'rounded-l-md rounded-br-md ml-lg bg-primary'
      "
      data-cy="chat-message"
    >
      <span
        *ngIf="!production"
        [ngClass]="part.kind !== ANSWER ? 'text-on-surface' : 'text-on-primary'"
        [innerHTML]="part.content"
      >
      </span>
      <ngx-typed-js
        *ngIf="production"
        [ngClass]="part.kind !== ANSWER ? 'text-on-surface' : 'text-on-primary'"
        [strings]="[part.content]"
        [showCursor]="false"
        (completed)="setOptionsOrNextMessage()"
      >
        <span class="typing"></span>
      </ngx-typed-js>
    </div>
  </div>

  <!--  Answer -->
  <div
    class="flex-1 flex flex-col space-y-md justify-end py-md"
    [hidden]="[ASK, ASK_TEXT, ASK_CAROUSEL].indexOf(data.currentMessage?.kind) >= 0"
  >
    <!--Carousel-->
    <div class="keen-slider" #sliderRef hidden="true">
      <img [src]="image" alt="" class="keen-slider__slide " *ngFor="let image of data.currentMessage?.images"
           (click)="selectImage(image)"/>
    </div>

    <div
      class="flex flex-col"
      autofocus="true"
      *ngIf="data.askText"
      [formGroup]="maudForm">
      <div
        class="flex">
        <input
          class="w-full bg-background text-on-background py-md px-md font-semibold leading-normal border border-primary focus:border-primary focus:outline-none appearance-none"
          placeholder="Digite aqui"
          autofocus
          type="text"
          formControlName="input"
          (keyup.enter)="inputText(null, $event)"
          #maudinput
          data-cy="ask_text"
        />
        <button class="ml-sm py-2 px-4 rounded hover:bg-primary-hover bg-primary text-on-primary"
        (click)="inputText()">
          Enviar
        </button>
      </div>
      <span
        class="pt-sm m0 text-error text-sm"
        *ngIf="input.invalid && (input.touched || input.dirty)">
        Adicione seus dados, para conseguirmos entrar em contato
      </span>
      <span
        *ngIf="!input.invalid || (!input.touched && !input.dirty)"
        class="pt-sm m0 text-on-background text-sm">
        Aperte "ENTER" para enviar
      </span>
    </div>

    <!--Answer Kind Button-->
    <button
      class="w-full text-on-background p-md bg-background  hover:text-on-primary hover:bg-primary border border-on-background font-semibold leading-normal"
      *ngFor="let option of data?.options"
      [disabled]="data.uploading"
      (click)="selectOption(option)"
    >
      {{ option.content }}
    </button>
  </div>
</div>
<!-- Input Hidden for Upload -->
<input type="file"  [hidden]="true" (change)="uploadFile($event)" #upload>
