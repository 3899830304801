import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  NG_VALUE_ACCESSOR,
  Validators,
} from '@angular/forms';
import { Profile } from '@app-angular/maudlib';
import { Observable } from 'rxjs';
import { validateSlug } from '../validate-slug.validator';
import { CoreService } from '../../core/core.service';

@Component({
  selector: 'app-profile-free',
  template: `
    <!--Form-->
    <form
      class="flex-column w-full"
      [formGroup]="profileForm"
      *ngIf="(loading | async) === false"
    >
      <mat-form-field appearance="fill" class="w-full">
        <mat-label>E-mail que chegará o orçamento</mat-label>
        <input
          matInput
          ngDefaultControl
          formControlName="email"
          data-cy="email"
        />
      </mat-form-field>
      <mat-form-field appearance="fill" class="w-full">
        <mat-label>Nome que você quer no link</mat-label>
        <input
          matInput
          ngDefaultControl
          formControlName="slug"
          data-cy="slug"
        />
        <mat-hint align="end">
          {{
            (validatingSlug | async)
              ? 'Validando se o nome está disponível'
              : 'Ex: app.maudbot.com/nomequevoceescolheu'
          }}
        </mat-hint>
        <mat-progress-bar
          *ngIf="validatingSlug | async"
          mode="indeterminate"
        ></mat-progress-bar>
        <mat-error *ngIf="profileForm.get('slug').hasError('invalid')">
          Esse link já foi utilizado, selecione outro por gentileza
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="fill" class="w-full">
        <mat-label>Seu nome</mat-label>
        <input
          matInput
          ngDefaultControl
          formControlName="name"
          data-cy="name"
        />
      </mat-form-field>
      <mat-form-field appearance="fill" class="w-full">
        <mat-label>Instagram do studio</mat-label>
        <input
          matInput
          ngDefaultControl
          formControlName="instagram"
          data-cy="instagram"
        />
      </mat-form-field>
      <mat-form-field appearance="fill" class="w-full">
        <mat-label>Nome do studio</mat-label>
        <input
          matInput
          ngDefaultControl
          formControlName="displayName"
          data-cy="displayName"
        />
      </mat-form-field>
      <mat-form-field appearance="fill" class="w-full">
        <mat-label>Whatsapp</mat-label>
        <input
          matInput
          ngDefaultControl
          formControlName="whatsapp"
          data-cy="whatsapp"
        />
      </mat-form-field>
    </form>

    <button
      class="w-full h-16 text-xl bg-primary text-on-primary"
      mat-raised-button
      type="submit"
      data-cy="save"
      [disabled]="
        !profileForm.valid ||
        (saving | async) ||
        (loading | async) ||
        (validatingSlug | async)
      "
      (click)="clickSave()"
    >
      <div class="grid grid-cols-3">
        <div class="flex justify-end items-center">
          <mat-spinner [diameter]="20" *ngIf="saving | async"></mat-spinner>
        </div>
        <span>Salvar</span>
      </div>
    </button>
  `,
  styles: [],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ProfileFreeComponent), // replace name as appropriate
      multi: true,
    },
  ],
})
export class ProfileFreeComponent implements OnInit, OnChanges {
  @Input()
  profile: Profile;
  @Input()
  saving: Observable<boolean>;
  @Input()
  loading: Observable<boolean>;
  @Input()
  validatingSlug: Observable<boolean>;

  @Output()
  save: EventEmitter<Profile> = new EventEmitter<Profile>();

  profileForm: FormGroup;

  // TODO: Maybe CoreService shouldn't be here, but how to sendo the service to the validators?
  constructor(private fb: FormBuilder, private coreService: CoreService) {}

  ngOnInit(): void {
    this.profileForm = this.fb.group(
      {
        email: [this.profile.email, [Validators.required, Validators.email]],
        slug: [
          this.profile.slug,
          [Validators.nullValidator, Validators.required],
          validateSlug(this.coreService),
        ],
        // maudName: ['],
        name: [this.profile.name],
        instagram: [this.profile.instagram],
        displayName: [this.profile.displayName],
        whatsapp: [this.profile.whatsapp],
      },
      {
        updateOn: 'blur',
      }
    );
  }

  ngOnChanges(): void {}

  getErrorMessage(email: string) {
    return 'Hola ermano';
  }

  clickSave() {
    this.save.emit(this.profileForm.value);
  }
}
