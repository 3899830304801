import {
  AbstractControl,
  AsyncValidatorFn,
  ValidationErrors,
} from '@angular/forms';
import { Observable, of, timer } from 'rxjs';
import { CoreService } from '../core/core.service';
import { concatMap, map, tap } from 'rxjs/operators';

export function validateSlug(coreService: CoreService): AsyncValidatorFn {
  return (control: AbstractControl): Observable<ValidationErrors | null> => {
    return coreService
      .isValidSlug(control.value)
      .pipe(map(valid => valid ? null : { invalid: true }));

    // of(control.value === 'foobar' ? null : { error: 'hooo' });
    // return of(control.value === 'foobar' ? null : { error: 'hooo' });
  };
}

// export function validateSlug(coreService: CoreService): AsyncValidatorFn {
//   return (control: AbstractControl): Observable<ValidationErrors> => {
//     return of(null);
//     // return of(timer(500)).pipe(
//     //   concatMap(() => null),
//     //   tap(() => console.log('searched'))
//     // );
//
//     // this.profileForm.get('slug').valueChanges.pipe(
//     //   debounceTime(300),
//     //   distinctUntilChanged()
//     // );
//     // return control.value === 'foobar' ? null : Promise.resolve({valid: true});
//     // return of(control.value === 'foobar' ? null : {valid: true});
//     // return coreService.isValidSlug(control.value)
//     //   .pipe(
//     //     map(valid => valid ? null : {valid: false})
//     //   );
//   };
// }
