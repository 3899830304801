import { Component, OnInit } from '@angular/core';
import { CoreService } from '../core/core.service';
import firebase from 'firebase';
import User = firebase.User;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(private coreService: CoreService) { }

  ngOnInit(): void {
    console.log('INit but without any screen');
  }

  logged(user: User) {
    this.coreService.logged(user);
  }
}
