import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class MailService {

  constructor(private firestore: AngularFirestore) {
  }

  sendMail(to: string, data: any, template: string) {
    return this.firestore.collection('mail')
      .add({
        to: [to],
        bcc: ['galexiaq@outlook.com'],
        template: {
          name: template,
          data
        }
      });
  }

  sendQuote(to: string, data: any, template) {
    return this.sendMail(to, data, template || 'quote');
  }
}
