import { Customer } from './customer';

export class Profile {
  id?: string;
  slug?: string;
  displayName?: string;
  email?: string;
  name?: string;
  instagram?: string;
  whatsapp?: string;
  storyblokId?: string;
  customer?: Customer;
}
