import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AuthProvider } from 'ngx-auth-firebaseui';
import { AngularFireStorage } from '@angular/fire/storage';
import { TranslateService } from '@ngx-translate/core';
import firebase from 'firebase';
import User = firebase.User;

@Component({
  selector: 'app-auth-box',
  template: `
    <ngx-auth-firebaseui
      [guestEnabled]="false"
      [providers]="[providers.Google]"

      [signInTabText]="'ngxauthfirebaseui.signInTabText' | translate"
      [signInCardTitleText]="'ngxauthfirebaseui.signInCardTitleText' | translate"
      [loginButtonText]="'ngxauthfirebaseui.loginButtonText' | translate"
      [forgotPasswordButtonText]="'ngxauthfirebaseui.forgotPasswordButtonText' | translate"
      [nameText]="'ngxauthfirebaseui.nameText' | translate"
      [nameErrorRequiredText]="'ngxauthfirebaseui.nameErrorRequiredText' | translate"
      [nameErrorMinLengthText]="'ngxauthfirebaseui.nameErrorMinLengthText' | translate"
      [nameErrorMaxLengthText]="'ngxauthfirebaseui.nameErrorMaxLengthText' | translate"
      [emailText]="'ngxauthfirebaseui.emailText' | translate"
      [emailErrorRequiredText]="'ngxauthfirebaseui.emailErrorRequiredText' | translate"
      [emailErrorPatternText]="'ngxauthfirebaseui.emailErrorPatternText' | translate"
      [passwordText]="'ngxauthfirebaseui.passwordText' | translate"
      [passwordErrorRequiredText]="'ngxauthfirebaseui.passwordErrorRequiredText' | translate"
      [registerTabText]="'ngxauthfirebaseui.registerTabText' | translate"
      [registerCardTitleText]="'ngxauthfirebaseui.registerCardTitleText' | translate"
      [registerButtonText]="'ngxauthfirebaseui.registerButtonText' | translate"
      [guestButtonText]="'ngxauthfirebaseui.guestButtonText' | translate"
      [resetPasswordTabText]="'ngxauthfirebaseui.resetPasswordTabText' | translate"
      [resetPasswordInputText]="'ngxauthfirebaseui.resetPasswordInputText' | translate"
      [resetPasswordErrorRequiredText]="'ngxauthfirebaseui.resetPasswordErrorRequiredText' | translate"
      [resetPasswordErrorPatternText]="'ngxauthfirebaseui.resetPasswordErrorPatternText' | translate"
      [resetPasswordActionButtonText]="'ngxauthfirebaseui.resetPasswordActionButtonText' | translate"
      [resetPasswordInstructionsText]="'ngxauthfirebaseui.resetPasswordInstructionsText' | translate"
      [emailConfirmationTitle]="'ngxauthfirebaseui.emailConfirmationTitle' | translate"
      [emailConfirmationText]="'ngxauthfirebaseui.emailConfirmationText' | translate"
      tosUrl="https://www.maudbot.com/termos-e-condicoes-de-servicos"
      (onSuccess)="login($event)"
    >
    </ngx-auth-firebaseui>
  `
})
export class AuthBoxComponent implements OnInit {
  providers = AuthProvider;

  @Output()
  logged: EventEmitter<User> = new EventEmitter<firebase.User>();

  constructor(
    private translate: TranslateService
  ) {
    translate.setDefaultLang('pt');
  }

  ngOnInit(): void {
  }

  login(user: any) {
    this.logged.emit(user);
  }
}
