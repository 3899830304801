import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PricingComponent } from './pricing.component';
import { PricingOptionComponent } from './pricing-option/pricing-option.component';

const routes: Routes = [{ path: '', component: PricingComponent }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PricingRoutingModule {
  static components = [PricingComponent, PricingOptionComponent];
}
