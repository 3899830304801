import { NgModule } from '@angular/core';
import { LoginRoutingModule } from './login-routing.module';
import { NgxAuthFirebaseUIModule } from 'ngx-auth-firebaseui';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  imports: [
    SharedModule,
    LoginRoutingModule,
    NgxAuthFirebaseUIModule
  ],
  declarations: [LoginRoutingModule.components],
})
export class LoginModule {}
