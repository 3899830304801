import { Component, Input, OnInit } from '@angular/core';
import { SubscribedPlan } from '../../core/model/subscribed-plan';

@Component({
  selector: 'app-subscription-info',
  template: `
    <p class="text-dm mb-md">
      Você assinou o plano
      <span class="whitespace-no-wrap">{{subscribedPlan.name}},</span>
      no valor de
      <span class="whitespace-no-wrap">{{subscribedPlan.price}}.</span>
      Clique no ícone de ferramenta para recibos, atualizar assinatura e pagamento
    </p>
  `,
  styles: [
  ]
})
export class SubscriptionInfoComponent implements OnInit {

  @Input()
  subscribedPlan: SubscribedPlan;

  constructor() { }

  ngOnInit(): void {
  }

}
