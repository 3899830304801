import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-profile-paid',
  template: `
    <p>
      profile-paid works!
    </p>
  `,
  styles: [
  ]
})
export class ProfilePaidComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
