import { Injectable } from '@angular/core';
import * as amplitude from 'amplitude-js';

import { environment as env } from '../environments/environment';
import { bindCallback, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EventTrackingService {
  private initiated = false;
  private client: string;

  constructor() {
    if (!this.initiated) {
      amplitude.getInstance().init(env.amplitude.apiKey);
      this.initiated = true;
    }
  }

  trackEvent(name: string, params: any = {}): Observable<any> {
    return new Observable((subscriber) => {
      const identity = new amplitude.Identify().set('tattooer', this.client);
      amplitude.getInstance().identify(identity);
      amplitude
        .getInstance()
        .logEvent(name, { ...params, client: this.client });
      subscriber.complete();
    });
  }

  setClient(client: string) {
    this.client = client;
  }
}
