import { Component, OnInit } from '@angular/core';
import { CoreService } from '../core/core.service';
import { Observable } from 'rxjs';
import { Profile } from '@app-angular/maudlib';
@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css']
})
export class AccountComponent {
  state = {
    saving: this.coreService.savingProfile,
    loading: this.coreService.loadingProfile,
    validatingSlug: this.coreService.validatingSlug
  };
  profile: Observable<Profile>;
  localProfile: Profile;

  constructor(private coreService: CoreService) {
    this.profile = this.coreService.loadProfile();
  }

  async onSave(profile: Profile) {
    await this.coreService.saveProfile(profile);
  }
}
