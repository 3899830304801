import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccountComponent } from './account.component';
import { ProfileFreeComponent } from './profile-free/profile-free.component';
import { ProfilePaidComponent } from './profile-paid/profile-paid.component';
import { SubscriptionInfoComponent } from './subscription-info/subscription-info.component';

const routes: Routes = [{ path: '', component: AccountComponent }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AccountRoutingModule {
  static components = [
    AccountComponent,
    ProfileFreeComponent,
    ProfilePaidComponent,
    SubscriptionInfoComponent
  ];
}
